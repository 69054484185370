@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap);
body {
  margin: 0;
  font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: #865cfa;
}

.h1 {
  font-size: 35px;
}

.h2 {
  font-size: 25px;
}

.h3 {
  font-size: 20px;
}

.h3_5 {
  font-size: 17px;
}

.container {
  width: 50%;
  padding: 100px;
}

@media only screen and (max-width: 550px){
  .h1 {
    font-size: 25px;
  }

  .h2 {
    font-size: 20px; 
  }

  .h3 {
    font-size: 17px;
  }

  .h3_5 {
    font-size: 14px;
  }

  .container {
    width: 70vw;
    padding: 10vw;
  }

  .button {
    font-size: 15px !important;
  }
}

.button:hover {
  background: #0001;
}

.button {
  cursor: pointer;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  opacity: 1 !important;
}

input {
  font-family: 'Inter', serif;
  border-style: none;
  border-bottom-style: solid;
  border-bottom-width: 2px;
  border-bottom-color: #ccc;
  padding: 5px;
  padding-left: 0px;
  padding-bottom: 3px;
  outline: none;
  opacity: 1 !important;
}
